import React, { Component } from 'react';
import { Container, Alert } from 'react-bootstrap';

import { InfoBoard } from '../components/partials/InfoBoard';
import { Loading } from './Loading';
import { CountDown } from '../components/CountDown';

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {},
            config: props.config,
            eventOnProgress: props.config.currentEvent.hasStarted || false
        };
    }

    async componentDidMount() {
        const data = await this.props.services.apiService.getTemperature();

        this.setState({
            loading: false,
            data: data
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <Container className="text-center mt-5">
                    <Loading />
                </Container>
            );
        }

        if (!this.state.loading && this.state.eventOnProgress) {
            return (<InfoBoard data={this.state.data} />);
        }

        return (
            <Container className="text-center mt-5">
                <Alert variant="light">
                    {this.state.config.currentEvent.message}
                </Alert>
                <CountDown dateTime={this.state.config.currentEvent.startDate} />
            </Container>
        );

    }
}