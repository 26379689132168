import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import moment from 'moment';

import { SimpleValue } from "../cards/SimpleValue";
import { LinearChart } from "../linearChart";


export class InfoBoard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTemperature: props.data.current.temperature / 100,
            currentHumidity: props.data.current.humidity / 100,
            avgTemperature: props.data.current.avgTemperature / 100,
            avgHumidity: props.data.current.avgHumidity / 100,
            maxTemperature: props.data.current.highestTemperature / 100,
            minTemperature: props.data.current.lowestTemperature / 100,
            maxHumidity: props.data.current.highestHumidity / 100,
            minHumidity: props.data.current.lowestHumidity / 100,
            timeLine: {
                labels: props.data.timeLine.times.map((dateTime) => {
                    const date =  new Date(dateTime);
                    return moment(date).format('DD/MM HH:mm');
                }),
                humidities: props.data.timeLine.humidities.map((humidity) => humidity / 100),
                temperatures: props.data.timeLine.temperatures.map((temperature) => temperature / 100)
            }
        };
    }


    render() {
        return(
            <Container>
                <Row md={{ offset: 2, span: 8 }} className="mt-5">
                    <Col md={3}>
                        <SimpleValue title="Temperatura Actual" value={this.state.currentTemperature + 'º'} />
                    </Col>

                    <Col md={3}>
                        <SimpleValue title="Temperatura media" value={this.state.avgTemperature + 'º'} />
                    </Col>

                    <Col md={3}>
                        <SimpleValue title="Humedad Actual" value={this.state.currentHumidity + '%'} />
                    </Col>

                    <Col md={3}>
                        <SimpleValue title="Humedad media" value={ this.state.avgHumidity + '%' } />
                    </Col>
                </Row>
                <Row md={{ offset: 2, span: 8 }} className="mt-1">
                    <Col md={3}>
                        <SimpleValue title="Temperatura máxima" value={ this.state.maxTemperature + 'º' } />
                    </Col>

                    <Col md={3}>
                        <SimpleValue title="Temperatura minima" value={ this.state.minTemperature + 'º' } />
                    </Col>

                    <Col md={3}>
                        <SimpleValue title="Humedad máxima" value= { this.state.maxHumidity + '%' } />
                    </Col>

                    <Col md={3}>
                        <SimpleValue title="Humedad mínima" value={ this.state.minHumidity + '%' } />
                    </Col>
                </Row>
                <Row md={{ offset: 2, span: 8 }} className="mt-2">
                    <Col md={6}>
                        <LinearChart title="Temperatura" labels={this.state.timeLine.labels} chartData={this.state.timeLine.temperatures} color="red" />
                    </Col>
                    <Col md={6}>
                        <LinearChart title="Humedad" labels={this.state.timeLine.labels} chartData={this.state.timeLine.humidities} color="blue" />
                    </Col>
                </Row>
            </Container>
        );
    }
}