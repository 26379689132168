import { Component } from "react";
import Cookies from "js-cookie";

import "./GoogleLogin.scss";

export class GoogleLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayLoginButton: false,
            isLogged: false,
            apiService: this.props.apiService,
            user: null,
            params: {
                client_id: "307880938557-acbupl4ivb61jr0f5uhdafnm75e3r8dv.apps.googleusercontent.com", //ToDo: Read from config
                redirect_uri: this.props.apiService.getGoogleOAuthRedirectUrl(),
                response_type: "code",
                scope: "email"
            }
        };
    }

    componentDidMount() {
        const displayLoginButton = Cookies.get("admin") === "true";
        this.setState({ displayLoginButton: displayLoginButton });
    }

    openIframe({ params, apiService }) {
        const loginWindow = window.open('/','_blank','width=600,height=600');
        loginWindow.addEventListener('load', () => {
            loginWindow.document.title = 'Inicia sesión con Google';
            const form = loginWindow.document.createElement('form');
            form.method = 'POST';
            form.action = "https://accounts.google.com/o/oauth2/v2/auth";

            for (const key in params) {
                const input = loginWindow.document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = params[key];
                form.appendChild(input);
            }

            loginWindow.document.body.appendChild(form);
            form.submit();
        });

        const bc = new BroadcastChannel('auth-channel');
        bc.onmessage = (event) => {
            if (event.data.userName && event.data.token) {
                apiService.setSession(event.data.userName, event.data.token);
                loginWindow.close();

                this.setState({
                    isLogged: true,
                    user: event.data.userName
                });

                this.props.loginCallback();
            }
        };
    }

    render() {
        if (!this.state.displayLoginButton || this.state.isLogged) {
            return null;
        }

        return(
            <button type="button" onClick={() => this.openIframe(this.state)} className="login-with-google-btn">
                Inicia sesión con Google
            </button>
        );
    }
}
