import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
  import { Line } from 'react-chartjs-2'


export class LinearChart extends Component {
    constructor(props) {
        super(props);
        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
        );

        this.state = {
            options: {
                plugins: { legend: { display: false } }
            },
            data: {
                labels: this.props.labels,
                datasets: [
                    {

                        data: this.props.chartData,
                        fill: false,
                        backgroundColor: this.props.color,
                        borderColor: this.props.color,
                        yAxisID: 'y'
                    }
                ]
            }
        }
    }

    render () {
        return (
            <Card className="text-center mt-1">
                <Card.Header className="fs-5">
                    {this.props.title}
                </Card.Header>
                <Card.Body >
                    <Line options={this.state.options} data={this.state.data} />
                </Card.Body>
            </Card>
        );
    }
}