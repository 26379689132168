import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { GoogleLogin } from './buttons/GoogleLogin';

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiService: props.services.apiService
        }
    }

    renderAdminMenu() {
        return (
            <NavDropdown title="Admin" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/admin/users" >
                    Usuarios
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/events">
                    Eventos
                </NavDropdown.Item>
                <NavDropdown.Item href="/admin/devices">
                    Dispositivos
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item >
                    Cerrar sesión
                </NavDropdown.Item>
          </NavDropdown>
        );
    }

    render() {
        return (
            <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand  as={Link} to="/">PartyWeather.org</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/historical" >Historicos</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            { 
                               this.state.apiService.isLogged() ? this.renderAdminMenu() : <GoogleLogin apiService={ this.state.apiService } loginCallback={ () => this.forceUpdate() } />
                            }
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}