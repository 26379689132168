import { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

export class DynamicForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            title: props.title,
            buttons: props.buttons,
            fields: props.fields,
            show: props.showModal,
            isValidForm: false,
            hasBeenSubmitted: false
        }
    }

    handleClose = () => {
        this.setState({ show: false });
        this.props.callback({ show: false });
    }

    validateForm () {
        const fields = this.state.fields;
        let valid = true

        fields.forEach((field, index) => {
            if (field.regexp) {
                const pattern = new RegExp(field.regexp);
                fields[index].valid = pattern.test(field.value);
            }

            if (!fields[index].valid) {
                valid = false;
            }
        });

        this.setState({ fields, hasBeenSubmitted: true });
        this.forceUpdate();

        if (valid) {
            this.props.callback({fields, show: false});
        }
    }

    handleChange (index, value) {
        const fields = this.state.fields;
        fields[index].value = value;
        this.setState({ fields });
    }

    drawField(index) {
        const {type, disabled } = this.state.fields[index];
        switch (this.state.fields[index].type) {
            case 'checkbox':
                return (disabled ? <Form.Check type="checkbox" checked={this.state.fields[index].value} readOnly /> : <Form.Check type="checkbox" checked={this.state.fields[index].value} onChange={(event) => this.handleChange(index, event.target.value)} />);
            default:
                return (disabled ? <Form.Control type={type} value={this.state.fields[index].value} readOnly /> : <Form.Control value={this.state.fields[index].value} type={type} onChange={(event) => this.handleChange(index, event.target.value)}/>);
        }
    }

    drawForm () {
        return this.state.fields.map((input, index) => {
            return (
                <Form.Group key={index} className="mb-3">
                    <Form.Label>{input.label}</Form.Label>
                    { this.drawField(index) }
                    <Form.Control.Feedback />
                </Form.Group>
            );
        });
    }


    drawButtons () {
        return this.state.buttons.map((button, index) => {
            return (
                <Button onClick={() => this.validateForm() } key={index} variant={button.variant} >
                    {button.label}
                </Button>
            );
        });
    }

    render () {
        return (
            <>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={true}
                >
                    <Modal.Header closeButton>
                    <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form validated={this.state.hasBeenSubmitted}>
                            { this.drawForm() }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        { this.drawButtons() }
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
};
