import React from 'react';
import { Container, Table, Card, Button, Spinner } from 'react-bootstrap';
import { AuthenticatedComponent } from '../../components/AuthenticatedComponent';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DynamicForm } from '../../components/DynamicForm';

export class Users extends AuthenticatedComponent {
    constructor(props) {
        super(props);

        this.state = {
            apiService: props.apiService,
            users: [],
            loading: true,
            showCreateUserModal: false,
            title: "Crear usuario",
            buttons: [
                {
                    label: 'Guardar',
                    variant: 'success',
                    type: 'submit'
                }
            ],
            fields: [
                {
                    type: 'email',
                    label: 'Email',
                    required: true,
                    value: '',
                    regexp: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
                    valid: false
                },
                {
                    type: 'text',
                    label: 'Username',
                    required: true,
                    value: '',
                    regexp: '^[a-zA-Z0-9]{4,}$',
                    valid: false
                },
                {
                    type: 'checkbox',
                    label: 'Administrador activado',
                    value: true,
                    disabled: true,
                    valid: true
                }
            ]
        };
    }

    async componentDidMount() {
        const users = await this.state.apiService.getAdminList();
        this.setState({ users, loading: false });
    }

    resetCreateUserForm = () => {
        const fields = this.state.fields;
        fields[0].value = '';
        fields[1].value = '';

        fields[0].valid = false;
        fields[1].valid = false;

        this.setState({ fields });
    }

    handleShowCreateUserModal = () => {
        this.resetCreateUserForm();
        this.setState({ showCreateUserModal: true });
        this.forceUpdate();
    }

    createUserFormCallback = async (state) => {
        this.setState({ fields: state.fields || this.state.fields, showCreateUserModal: state.show });

        //TODO: add to the list
        await this.state.apiService.createAdminUser(state.fields[0].value, state.fields[1].value);
        this.resetCreateUserForm();
        this.forceUpdate();
    }

    renderUserList () {
        return this.state.users.map((user, index) => {
            return (
                <tr key={index}>
                    <td>{user.email}</td>
                    <td>{user.username}</td>
                    <td>{user.is_enabled ? 'Si' : 'No'}</td>
                </tr>
            )
        })
    }

    render() {
        return (
            <Container className='mt-5'>
                <Card>
                    <Card.Header>
                        <Card.Title>Usuarios</Card.Title>
                    </Card.Header>
                    <Card.Body className='text-center'>
                        { this.state.loading ? <Spinner  animation="border" /> :
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Username</th>
                                        <th>Activo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.renderUserList() }
                                </tbody>
                            </Table>
                        }
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="success" onClick={this.handleShowCreateUserModal} >
                            <FontAwesomeIcon icon={faPlus} />
                            <span style={{paddingLeft: "10px"}}>Añadir usuario</span>
                        </Button>
                    </Card.Footer>
                </Card>
                {
                    this.state.showCreateUserModal &&
                    <DynamicForm 
                        callback={this.createUserFormCallback}
                        showModal={this.state.showCreateUserModal}
                        fields={this.state.fields}
                        buttons={this.state.buttons}
                        title={this.state.title}
                    />
                }
            </Container>
        );
    }
}
