import React, { Component } from 'react';
import { Container, Row, Card, Col, Form } from 'react-bootstrap';
import { InfoBoard } from '../components/partials/InfoBoard';
import { Loading } from './Loading';

export class Historical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            events: [],
            event: null,
            temperature: {}
        };
    }

    async componentDidMount() {
        let events = await this.props.services.apiService.getEvents();
        events = events.filter(event => !event.is_current);

        const event = events[0];
        const temperature = await this.props.services.apiService.getTemperature(event.id);

        this.setState({
            events,
            event,
            temperature,
            loading: false
        });
    }

    change = async (changeEvent) => {
        this.setState({
            loading: true
        });

        const id = changeEvent.target.value;
        const temperature = await this.props.services.apiService.getTemperature(id);
        const event = this.state.events.find(event => event.id === id);

        this.setState({
            event,
            temperature,
            loading: false
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <Container className="text-center mt-5">
                    <Loading />
                </Container>
            );
        }

        return (
            <Container>
                <Row md={{ offset: 2, span: 8 }} className="mt-5">
                    <Col md={12}>
                        <Card>
                            <Card.Header>
                                Selecciona un evento
                            </Card.Header>
                            <Card.Body>
                                <Form.Select onChange={this.change} value={this.state.event.id}>
                                    {this.state.events.map((event, index) => {
                                        return (
                                            <option key={index} value={event.id}>{event.name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <InfoBoard data={this.state.temperature} />
                </Row>
            </Container>
        )
    }
}
