import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';



import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Header } from './components/Header';

import { Home } from './views/Home';
import { Historical } from './views/Historical';
import { Loading } from './views/Loading';
import { Users } from './views/admin/Users';

import { ApiService } from './services/Api';

class App extends Component {
    constructor() {
        super();
        const swal = withReactContent(Swal)
        this.state = {
            services: {
                apiService: new ApiService(axios, swal)
            },
            config: {},
            loading: true
        };

        const query = new URLSearchParams(window.location.search);
        const userName = query.get('userName');
        const token = query.get('token');

        if(userName && token) {
            const bc = new BroadcastChannel('auth-channel');
            bc.postMessage({ userName, token });
        }
    }

    async componentDidMount() {
        document.title = "PartyWeather.org";
        const config = await this.state.services.apiService.getConfig();
        this.setState({ config, loading: false });
    }

    render() {

        if(this.state.loading) {
            return (
                <Loading />
            );
        } else {
            return(
                <BrowserRouter>
                    <Header services={ this.state.services } />
                    <Routes>
                        <Route index element={<Home config={ this.state.config } services={ this.state.services } />} />
                        <Route path="historical" element={<Historical services={ this.state.services } />} />
                        <Route path="admin">
                            <Route index path="users" element={<Users apiService={this.state.services.apiService} />} />
                            <Route index path="events" element={<Users apiService={this.state.services.apiService} />} />
                            <Route index path="devices" element={<Users apiService={this.state.services.apiService} />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            );
        }
    }
}

export default App;
