import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';

export class SimpleValue extends Component {
    render() {
        return (
            <Card className="text-center mt-1">
                <Card.Header className="fs-6">
                    {this.props.title}
                </Card.Header>
                <Card.Body className="fs-1 fw-bold">
                    {this.props.value}
                </Card.Body>
            </Card>
        );
    }
}