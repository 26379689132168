import moment from 'moment';
import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

export class CountDown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dateTime: props.dateTime,
            months: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            const { dateTime } = this.state;
            const then = moment(dateTime);
            const now = moment();
            const countdown = moment(then - now);
            const months = countdown.format('MM') - 1;
            const days = countdown.format('D');
            const hours = countdown.format('HH');
            const minutes = countdown.format('mm');
            const seconds = countdown.format('ss');
            this.setState({ months, days, hours, minutes, seconds });
        }, 1000);
    }


    render () {
        return (
            <Row className="mt-5" style={{display:'flex', justifyContent: 'space-between'}}>
                <Col md={2} className="mt-1">
                    <Card>
                        <Card.Header className="fs-6">
                            Meses
                        </Card.Header>
                        <Card.Body className="fs-1 fw-bold">
                            {this.state.months}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={2} className="mt-1">
                    <Card>
                        <Card.Header className="fs-6">
                            Días
                        </Card.Header>
                        <Card.Body className="fs-1 fw-bold">
                            {this.state.days}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={2} className="mt-1">
                    <Card>
                        <Card.Header className="fs-6">
                            Horas
                        </Card.Header>
                        <Card.Body className="fs-1 fw-bold">
                            {this.state.hours}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={2} className="mt-1">
                    <Card>
                        <Card.Header className="fs-6">
                            Minutos
                        </Card.Header>
                        <Card.Body className="fs-1 fw-bold">
                            {this.state.minutes}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={2} className="mt-1">
                    <Card>
                        <Card.Header className="fs-6">
                            Segundos
                        </Card.Header>
                        <Card.Body className="fs-1 fw-bold">
                            {this.state.seconds}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
};
