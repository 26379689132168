import PacmanLoader from "react-spinners/PacmanLoader";
import React, { Component } from 'react';

export class Loading extends Component {
  render() {
    return (
        <div className="loading">
            <PacmanLoader color={"#ffffff"} loading={true} size={20} />
        </div>
    );
  }
}