import { Component } from 'react';

export class AuthenticatedComponent extends Component {
    constructor(props) {
        super(props);
        if(!props.apiService.isLogged()) {
            window.location.href = '/';
        }
    }
}
